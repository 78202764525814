import React from "react";
import backgroundImage from "images/banners/bg-about-mission.jpg";
import styled from "styled-components";
import {
  AboutUsNav,
  BannerTitle,
  Layout,
  PageContainer,
  PressLinkBar,
  PressLinksList,
  SEO,
} from "components";
import { Col, Row } from "reactstrap";
import { device } from "utils";

const PressPage = ({ location }) => {
  const title = "About Us";
  const description = "Emerald Cloud Lab news articles";

  return (
    <Layout clearNav location={location}>
      <SEO
        image={backgroundImage}
        title={`${title} | Press and Media`}
        description={description}
        uri={location.pathname}
      />
      <BannerTitle image={backgroundImage}>{title}</BannerTitle>
      <PageContainer>
        <AboutUsNav section={location && location.pathname} />
        <Row>
          <ColContent xs={12} md={12} lg={12} xl={12}>
            <PressLinkBar />
            <PressLinksList />
          </ColContent>
        </Row>
      </PageContainer>
    </Layout>
  );
};

export default PressPage;

const ColContent = styled(Col)`
  padding-left: 5rem;
  margin-bottom: 5rem;
  @media not all and ${device.lg} {
    padding: 10px 7px;
  }
`;
